html, body, #cesiumContainer {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: fixed;
    overflow: hidden;
    font-family: sans-serif;
    color: #edffff;
    background-color: black;
}

body {
  -webkit-touch-callout: none;
  user-select: none;
}

[v-cloak] {
    display: none;
}

#toolbarLeft {
    position: absolute;
    top: 5px;
    left: 2px;
}

#toolbarRight {
    position: absolute;
    top: 5px;
    right: 2px;
}

.toolbarSwitches {
    display: flex;
    flex-direction: column;
    margin: 2px 3px;
    background-color: #30333680;
    border-radius: 8px;
}

.toolbarTitle {
    text-align: center;
    height: 24px;
    line-height: 24px;
    margin-top: 5px;
}

.toolbarContent {
    margin: 5px;
}

.toolbarSwitch {
    background-color: #303336;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    margin: 1px 0px 1px 0px;
    opacity: 0.5;
    padding-left: 50px;
    position: relative;
}

.toolbarSwitch input {
    display:none;
}

.slider::before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    border-radius: 50%;
    background-color: red;
    transition: .4s;
}

input:checked + .slider::before {
    background-color: green;
    transform: translateX(20px);
}

.fill-parent {
    width: 100%;
    height: 100%;
}

.cesium-toolbar-button .icon {
    filter: brightness(1.1);
}

.icon .mfa-button-width {
    width: 24px;
}

.svg-sat {
    background-image: url("../assets/images/icons/sat/2.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    filter: brightness(3.1);
    transform: rotate(-90deg);
}

.svg-groundstation {
    background-image: url("../assets/images/icons/sat/9.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    filter: brightness(3.1);
    margin-left: 2px;
}

.cesium-infoBox {
    width: calc(100% - 10px);
    max-width: 540px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 7px;
    border: none;
    box-sizing: border-box;
}

.cesium-infoBox-title {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.cesium-infoBox-container {
    float: left;
    position: absolute;
    top: 4px;
    left: 30px;
    height: 22px;
    margin: 0;
    padding: 0;
}

.cesium-infoBox-custom {
    background: transparent;
    border: 1px solid transparent;
    display: inline-block;
    font-size: 16px;
    height: 22px;
    width: 22px;
    padding: 0px;
    margin: 0px 3px;
}
